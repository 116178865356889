import { ChantierDTO } from '../data-access/http/dto/chantier-dto'

export class User {
    id: number
    nom: string
    prenom: string
    dernierChantierCode: string
    superAdmin: boolean
    droitsRH: boolean
    idUtilisateurSi: string
    chantiers: ChantierDTO[]

    constructor(init?: Partial<User>) {
        Object.assign(this, init)
    }

    getInitiales(): string {
        return (this.prenom[0] + this.nom[0]).toUpperCase()
    }

    getDisplayName(): string {
        return this.prenom + ' ' + this.nom
    }

    getChantierCode(): string {
        if (this.chantiers.length === 0) {
            return null
        }
        if (!this.dernierChantierCode) {
            return this.chantiers[this.chantiers.length - 1].code
        }
        return this.chantiers.find((chantier) => chantier.code === this.dernierChantierCode).code
    }
}
