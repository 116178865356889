import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { ApplicationDto } from './dto/application.dto'
import { ApplicationInfoDto } from './dto/application-info.dto'

@Injectable({
    providedIn: 'root',
})
export class ApplicationHttpService {
    constructor(private http: HttpClient) {}

    getApplications(): Observable<ApplicationDto[]> {
        return this.http.get<ApplicationDto[]>(environment.baseUrl + '/application/applications')
    }
    fetchData(codeApplication: string, chantierCode: string): Observable<ApplicationInfoDto> {
        return this.http.get<ApplicationInfoDto>(
            `${environment.baseUrl}/application/infos/${codeApplication}/${chantierCode}`,
        )
    }
    getGuideDetailleAjouterLocatairesTelephones(): Observable<Blob> {
        return this.http.get('assets/documents/Guide_detaille_ajouter_locataires_telephone.pdf', {
            responseType: 'blob',
        })
    }
}
