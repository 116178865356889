<div class="card p-3">
    <div class="card-body">
        <h4 class="card-title mb-4">Ajouter un document</h4>
        <form #ngFormRef #ngForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()" class="d-flex flex-column gap-3 form">
            <div>
                <label>Type de document</label>
                <select
                    formControlName="codeTypeDocumentGed"
                    [class.is-invalid]="form.get('codeTypeDocumentGed').invalid && ngForm.submitted"
                    class="form-select"
                >
                    <option *ngFor="let typeDocument of typesDocument" [value]="typeDocument.code">
                        {{ typeDocument.label }}
                    </option>
                </select>
                <div
                    *ngIf="this.form.get('codeTypeDocumentGed').invalid && ngForm.submitted"
                    class="invalid-feedback d-block"
                >
                    <div>Le type de document est obligatoire</div>
                </div>
            </div>
            <div>
                <label>Nom</label>
                <input
                    formControlName="fileName"
                    type="text"
                    [class.is-invalid]="form.get('fileName').invalid && ngForm.submitted"
                    class="form-control"
                />
                <div *ngIf="this.form.get('fileName').invalid && ngForm.submitted" class="invalid-feedback d-block">
                    <div>Veuillez saisir un nom de fichier</div>
                </div>
            </div>
            <div class="align-self-end d-flex align-items-center gap-4">
                <span *ngIf="form.get('file').valid || !ngForm.submitted">{{ form.get("file")?.value?.name }}</span>
                <ng-container *ngIf="form.get('file').invalid && ngForm.submitted">
                    <div *ngIf="form.get('file').errors?.['required']" class="invalid-feedback d-block">
                        <div>Veuillez sélectionner un fichier</div>
                    </div>
                    <div *ngIf="form.get('file').errors?.['maxFileSize']" class="invalid-feedback d-block">
                        <div>
                            Veuillez sélectionner un fichier plus léger. Taille max :
                            {{ form.get("file")?.errors?.["maxFileSize"]["expectedSize"] | byte }} ; Taille du fichier
                            sélectionné :
                            {{ form.get("file")?.errors?.["maxFileSize"]["actualSize"] | byte }}
                        </div>
                    </div>
                </ng-container>

                <label role="button" for="select-document"><i class="bi bi-paperclip fs-3"></i></label>
                <input
                    type="file"
                    class="d-none"
                    id="select-document"
                    (change)="onDocumentPicked($any($event.target).files[0])"
                />
                <app-spinner-button type="submit" design="primary" [ignoreSpinnerIf]="form.invalid"
                    >Ajouter</app-spinner-button
                >
            </div>
        </form>
    </div>
</div>
