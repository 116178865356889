import { Component, inject } from '@angular/core'
import { ParamDomainService } from '../../../../shared/domain/param.domain'
import { ParamCodeEnum } from '../../../../shared/models/enums/param-code.enum'
import { NgForOf } from '@angular/common'

@Component({
    selector: 'app-accueil-chantier-portlet-body',
    standalone: true,
    imports: [NgForOf],
    templateUrl: './accueil-chantier-portlet-body.component.html',
    styleUrl: './accueil-chantier-portlet-body.component.scss',
})
export class AccueilChantierPortletBodyComponent {
    protected readonly accueilChantierInfos: AccueilChantierInfo[]
    private readonly paramDomainService: ParamDomainService

    constructor() {
        this.paramDomainService = inject(ParamDomainService)
        this.accueilChantierInfos = JSON.parse(
            this.paramDomainService.getParamByCode(ParamCodeEnum.infosAccueilChantier).valeurParam,
        )
    }
}

interface AccueilChantierInfo {
    libelle: string
}
