<div class="d-flex">
    <div [class.red-text]="!value">
        {{ leftLabel }}
    </div>
    <div class="form-check form-switch ms-2">
        <input
            [disabled]="disabled"
            [checked]="value"
            class="form-check-input"
            type="checkbox"
            role="switch"
            (click)="toggleValue()"
        />
    </div>
    <div [class.blue-text]="value">
        {{ rightLabel }}
    </div>
</div>
