import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core'
import { TypeDocument, typesDocument } from '../../../../../data-access/constant/types-document'
import { NgForOf, NgIf } from '@angular/common'
import { FormControl, FormGroup, NgForm, ReactiveFormsModule, Validators } from '@angular/forms'
import { SpinnerButtonComponent } from '../../../../../../shared/ui/spinner-button/spinner-button.component'
import { maxFileSize } from '../../../../../../shared/validators/max-file-size.validator'
import { PrettyBytePipe } from '../../../../../../shared/pipes/pretty-byte.pipe'

@Component({
    selector: 'app-document-upload',
    standalone: true,
    imports: [NgForOf, ReactiveFormsModule, NgIf, SpinnerButtonComponent, PrettyBytePipe],
    templateUrl: './document-upload.component.html',
    styleUrl: './document-upload.component.scss',
})
export class DocumentUploadComponent {
    @Output()
    uploadDocument = new EventEmitter<DocumentUploadEvent>()

    protected form: FormGroup = new FormGroup<DocumentUploadForm>({
        fileName: new FormControl('', Validators.required),
        codeTypeDocumentGed: new FormControl('', Validators.required),
        file: new FormControl(null, [
            Validators.required,
            maxFileSize(DocumentUploadComponent.MAX_FILE_SIZE_EN_OCTETS),
        ]),
    })
    protected typesDocument: TypeDocument[] = typesDocument
    @ViewChild('ngForm')
    readonly ngForm: NgForm
    @ViewChild('ngFormRef')
    readonly ngFormRef: ElementRef
    private static readonly MAX_FILE_SIZE_EN_OCTETS = 20 * 1024 * 1024 // 20Mo

    onSubmit(): void {
        if (this.form.valid) {
            this.uploadDocument.emit({
                ngFormRef: this.ngFormRef,
                form: this.form,
                ngForm: this.ngForm,
            })
        }
    }

    onDocumentPicked(file: File): void {
        this.form.patchValue({ file })
    }
}

export interface DocumentUploadForm {
    fileName: FormControl<string>
    codeTypeDocumentGed: FormControl<string>
    file: FormControl<File>
}

export interface DocumentUploadFormValue {
    fileName: string
    codeTypeDocumentGed: string
    file: File
}

export interface DocumentUploadEvent {
    form: FormGroup<DocumentUploadForm>
    ngForm: NgForm
    ngFormRef: ElementRef
}
