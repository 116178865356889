import { inject, Injectable } from '@angular/core'
import { GlobalState } from '../data-access/state/global.state'
import { Subject } from 'rxjs'

/**
 * This state is meant to manage inter-module communication
 * It acts as a globally accessible cache
 */
@Injectable({ providedIn: 'root' })
export class GlobalDomainService {
    private readonly globalState = inject(GlobalState)

    // Selectors
    stopAllLocalSpinners$ = new Subject<void>()
    displayGlobalSpinner$ = this.globalState.store.select(GlobalState.getShouldDisplayGlobalSpinner)

    // Actions

    stopSpinner(): void {
        this.stopAllLocalSpinners$.next()
    }

    startGlobalSpinner(): void {
        this.globalState.store.update((state) => ({
            ...state,
            shouldDisplayGlobalSpinner: true,
        }))
    }

    stopGlobalSpinner(): void {
        this.globalState.store.update((state) => ({
            ...state,
            shouldDisplayGlobalSpinner: false,
        }))
    }
}
