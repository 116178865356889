<button
    (click)="onClick($event)"
    [class.btn-outline-primary]="design === 'secondary'"
    [class.btn-primary]="design === 'primary'"
    class="btn px-5 {{ buttonCustomClasses }}"
    [class.disabled]="displaySpinner() || disabled"
    [type]="type"
>
    <span *ngIf="displaySpinner()" class="spinner-border spinner-border-sm"></span>
    <ng-container *ngIf="!displaySpinner()">
        <ng-content />
    </ng-container>
</button>
