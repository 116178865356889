import { Component, inject } from '@angular/core'
import { ParamDomainService } from '../../../../shared/domain/param.domain'
import { ParamCodeEnum } from '../../../../shared/models/enums/param-code.enum'
import { NgOptimizedImage } from '@angular/common'

@Component({
    selector: 'app-wifi-portlet-body',
    standalone: true,
    imports: [NgOptimizedImage],
    templateUrl: './wifi-portlet-body.component.html',
    styleUrl: './wifi-portlet-body.component.scss',
})
export class WifiPortletBodyComponent {
    protected readonly codeWifiCourt: string
    protected readonly codeWifiLong: string
    private readonly paramDomainService = inject(ParamDomainService)

    constructor() {
        this.codeWifiCourt = this.paramDomainService.getParamByCode(ParamCodeEnum.wifiCourt)?.valeurParam
        this.codeWifiLong = this.paramDomainService.getParamByCode(ParamCodeEnum.wifiLong)?.valeurParam
    }
}
