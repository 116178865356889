import { Injectable } from '@angular/core'
import { Store } from '../../models/store'
import { Interimaire } from '../../models/interimaire'
import { Salarie } from '../../models/salarie'

export interface GlobalStateModel {
    lastInterimaireSearchedInDroitsRh: Interimaire
    lastSalarieSearchedInDroitsRh: Salarie
    shouldDisplayGlobalSpinner: boolean
}

@Injectable({ providedIn: 'root' })
export class GlobalState {
    store = new Store<GlobalStateModel>({
        lastInterimaireSearchedInDroitsRh: null,
        lastSalarieSearchedInDroitsRh: null,
        shouldDisplayGlobalSpinner: false,
    })

    static getLastInterimaireSearchedInDroitsRh = (state: GlobalStateModel): Interimaire =>
        state.lastInterimaireSearchedInDroitsRh
    static getLastSalarieSearchedInDroitsRh = (state: GlobalStateModel): Salarie => state.lastSalarieSearchedInDroitsRh
    static getShouldDisplayGlobalSpinner = (state: GlobalStateModel): boolean => state.shouldDisplayGlobalSpinner
}
