<div>
    <div>
        <p class="fw-bold mb-3 reduced-line-height title-custom-text-size ">
            Suivez les étapes pour ajouter tous les locataires dans vos contacts :
        </p>
    </div>
    <div class="mb-1 reduced-line-height">
        <ol class="li-custom-text-size">
            <li class="mb-1">Récupérer le fichier des locataires auprès du bailleur.</li>
            <li class="mb-1">Préparer le fichier CSV au format Google Contacts en suivant le guide.</li>
            <li>Importer le fichier CSV dans Google Contacts.</li>
        </ol>
    </div>
    <div class="mb-1">
        <img
            [ngSrc]="initMobile"
            alt="InitMobileSchema"
            width="245"
            height="64"
            class="d-block mx-auto img-fluid"
        />
    </div>
    <div>
        <p class="text-center fw-bold mb-3 reduced-line-height title-custom-text-size">
            C’est parti, vous pouvez profiter de vos nouveaux contacts.
        </p>
    </div>

    <div class="row app-link pt-2">
        <p (click)="onClick()" class="text-end pe-0" style="cursor: pointer">
            Accéder au guide détaillé
            <i class="bi bi-box-arrow-up-right"></i>
        </p>
    </div>
</div>
