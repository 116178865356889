import { Component, HostBinding, HostListener, inject, Input, signal } from '@angular/core'
import { delayWhen, of, tap } from 'rxjs'
import { NgIf } from '@angular/common'
import { GlobalDomainService } from '../../domain/global.domain'

@Component({
    selector: 'app-spinner-button',
    standalone: true,
    imports: [NgIf],
    templateUrl: './spinner-button.component.html',
    styleUrl: './spinner-button.component.scss',
})
export class SpinnerButtonComponent {
    @Input()
    design: 'primary' | 'secondary' = 'primary'
    @Input()
    disabled = false
    @Input()
    type: string
    @Input()
    ignoreSpinnerIf: boolean
    @Input()
    @HostBinding('class')
    customClasses = ''
    @Input()
    buttonCustomClasses = ''

    displaySpinner = signal(false)

    private readonly globalDomainService = inject(GlobalDomainService)

    @HostListener('click', ['$event'])
    onHostClick(event: MouseEvent): void {
        this.handleClick(event)
    }
    onClick(event: MouseEvent): void {
        this.handleClick(event)
    }

    private handleClick(event: MouseEvent): void {
        if (this.displaySpinner() || this.disabled) {
            event.stopPropagation()
            return
        }

        if (!this.ignoreSpinnerIf) {
            of('')
                .pipe(
                    tap(() => this.displaySpinner.set(true)),
                    delayWhen(() => this.globalDomainService.stopAllLocalSpinners$),
                    tap(() => this.displaySpinner.set(false)),
                )
                .subscribe()
        }
    }
}
