import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'byte', standalone: true })
export class PrettyBytePipe implements PipeTransform {
    transform(num: number, precision = 3, addSpace = true): string {
        const UNITS = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
        if (Math.abs(num) < 1) {
            return num + (addSpace ? ' ' : '') + UNITS[0]
        }

        const exponent = Math.min(Math.floor(Math.log10(num < 0 ? -num : num) / 3), UNITS.length - 1)
        const n = Number(((num < 0 ? -num : num) / 1024 ** exponent).toPrecision(precision))
        return (num < 0 ? '-' : '') + n + (addSpace ? ' ' : '') + UNITS[exponent]
    }
}
