import { Component, inject } from '@angular/core'
import { NgForOf } from '@angular/common'
import { ParamDomainService } from '../../../../shared/domain/param.domain'
import { ParamCodeEnum } from '../../../../shared/models/enums/param-code.enum'

@Component({
    selector: 'app-booster-box-portlet-body',
    standalone: true,
    imports: [NgForOf],
    templateUrl: './booster-box-portlet-body.component.html',
    styleUrl: './booster-box-portlet-body.component.scss',
})
export class BoosterBoxPortletBodyComponent {
    protected readonly boosterBoxLinks: BoosterBoxLink[]
    private readonly paramDomainService: ParamDomainService

    constructor() {
        this.paramDomainService = inject(ParamDomainService)
        this.boosterBoxLinks = JSON.parse(
            this.paramDomainService.getParamByCode(ParamCodeEnum.portletboosterboxtexte).valeurParam,
        )
    }
}

interface BoosterBoxLink {
    libelle: string
    lien: string
}
