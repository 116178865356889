import { inject, Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { FavIcon } from '../../../shared/models/enums/favicon.enum'
import { HostEnvironment, HostEnvironmentEnum } from '../../../shared/models/host-environment'
import { CoreUtils } from '../../utils/core.utils'

@Injectable({ providedIn: 'root' })
export class PortailIrisHostEnvironmentService extends HostEnvironment {
    id = HostEnvironmentEnum.portailIris
    url = 'portail-iris'

    private title = inject(Title)
    setFavicon(): void {
        CoreUtils.setFaviconElement(FavIcon.ICONE_PORTAIL_IRIS)
    }

    setTitle(): void {
        this.title.setTitle('Portail Iris')
    }
}
