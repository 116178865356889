import { LogoSetting } from '../../../shared/models/logo-setting'
import { PortailEnum } from '../../../shared/models/enums/portail.enum'

export const logoSettings: Map<PortailEnum, LogoSetting> = new Map([
    [
        PortailEnum.portailIris,
        {
            url: 'assets/images/logos/logo-iris.png',
            alt: 'Logo Portail Iris',
            label: 'Portail Iris',
            logoCssClass: 'ps-3 ',
            labelCssClass: 'me-2 ps-3 pe-3 ms-lg-1',
        },
    ],
    [
        PortailEnum.portailChantier,
        {
            url: 'assets/images/logos/logo-lg.png',
            alt: 'Portail',
            label: 'Portail Chantier',
            logoCssClass: 'ps-2',
            labelCssClass: 'ps-2 ms-lg-1',
        },
    ],
])
