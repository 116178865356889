import { Component, inject,} from '@angular/core'
import { NgOptimizedImage } from '@angular/common'
import { EvenementDomainService } from '../../../../shared/domain/evenement.domain'
import { ChantierDomainService } from '../../../../shared/domain/chantier.domain'
import { ApplicationDomainService } from '../../../domain/application.domain'


@Component({
    selector: 'app-ajouter-locataires-contacts-portlet-body',
    standalone: true,
    imports: [NgOptimizedImage],
    templateUrl: './ajout-locataires-contacts-portlet-body.component.html',
    styleUrl: './ajout-locataires-contacts-portlet-body.component.scss',
})
export class AjoutLocatairesContactsPortletBodyComponent {
    private readonly evenementDomainService = inject(EvenementDomainService)
    private readonly chantierDomainService = inject(ChantierDomainService)
    private readonly applicationDomainService = inject(ApplicationDomainService)
    protected readonly initMobile = 'assets/images/home/ajouter-locataires-contacts-body.png'

    onClick():void{
        this.evenementDomainService.createClickAjouterLocataireContactEvenement(this.chantierDomainService.selectedChantierCode()).subscribe()
        this.applicationDomainService.getGuideDetailleAjouterLocatairesTelephones().subscribe()
    }

}

