<div class="container">
    <div class="modal-header">
        <h3 class="modal-title mb-1">Espace documentation chantier {{ chantierCode }}</h3>
        <button (click)="dismissModal()" aria-label="Close" class="btn-close" type="button"></button>
    </div>
    <app-document-upload (uploadDocument)="onUploadDocument($event)" class="mb-3" />
    <app-document-list
        [documentsDataSource]="documentsDataSource"
        (gridReady)="onGridReady($event)"
        (updateDocumentIsActive)="onUpdateDocumentIsActive($event)"
        (updateDocumentIsFavori)="onUpdateDocumentIsFavori($event)"
        (documentClicked)="onDocumentClicked($event)"
    ></app-document-list>
</div>
