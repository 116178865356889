import { ChangeDetectorRef, Component, inject, Inject } from '@angular/core'
import { DocumentGedDto } from '../../../../shared/data-access/http/dto/document-ged.dto'
import { DatePipe, DOCUMENT, NgForOf, NgIf } from '@angular/common'
import { DocumentGed } from '../../../../shared/models/document-ged'
import { parseCustomDate } from '../../../../shared/utils/date.utils'
import { PieceJointeHttpService } from '../../../../shared/data-access/http/piece-jointe-http.service'
import { catchError, combineLatestWith, EMPTY, of, switchMap, tap } from 'rxjs'
import { PieceJointeDto } from '../../../../shared/data-access/http/dto/piece-jointe.dto'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AdminDocumentationModalComponent } from './admin-documentation-modal/admin-documentation-modal.component'
import { IServerSideGetRowsParams } from 'ag-grid-community'
import { DocumentGedHttpService } from '../../../../shared/data-access/http/document-ged-http.service'
import { PageResponse } from '../../../../shared/data-access/http/dto/page-dto'
import { ChantierDomainService } from '../../../../shared/domain/chantier.domain'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ApplicationHttpService } from '../../../data-access/http/application-http.service'
import {
    UpdateDocumentIsActiveEvent,
    UpdateDocumentIsFavoriEvent,
} from './admin-documentation-modal/document-list/document-list.component'
import { DocumentUploadEvent } from './admin-documentation-modal/document-upload/document-upload.component'
import { ToastrService } from 'ngx-toastr'
import { EvenementDomainService } from '../../../../shared/domain/evenement.domain'
import { GlobalDomainService } from '../../../../shared/domain/global.domain'

@Component({
    selector: 'app-documentation-chantier-portlet-body',
    standalone: true,
    imports: [NgForOf, DatePipe, NgIf, AdminDocumentationModalComponent],
    templateUrl: './documentation-chantier-portlet-body.component.html',
    styleUrl: './documentation-chantier-portlet-body.component.scss',
})
export class DocumentationChantierPortletBodyComponent {
    data: DocumentGed[]
    private readonly pieceJointeHttpService = inject(PieceJointeHttpService)
    private readonly documentGedHttpService = inject(DocumentGedHttpService)
    private readonly chantierDomainService = inject(ChantierDomainService)
    private readonly evenementDomainService = inject(EvenementDomainService)
    private readonly globalDomainService = inject(GlobalDomainService)
    private readonly applicationHttpService = inject(ApplicationHttpService)
    private readonly modalService = inject(NgbModal)
    private readonly toastr = inject(ToastrService)
    private readonly document = inject(DOCUMENT)
    private readonly cdr = inject(ChangeDetectorRef)

    constructor(@Inject('DATA') private dataInjected: string) {
        const dtos: DocumentGedDto[] = JSON.parse(this.dataInjected)
        this.data = dtos.map((dto) => this.mapDocumentDtoToDocument(dto))
    }

    downloadDocument(document: DocumentGed): void {
        this.pieceJointeHttpService
            .getPhotoById(document.document_id)
            .pipe(tap((pj: PieceJointeDto) => this.document.defaultView.open(pj.lambdaUrl)))
            .subscribe()
    }

    openDocumentsModale(): void {
        this.evenementDomainService.createClickAccesDocumentationEvenement().subscribe()
        const modalRef = this.modalService.open(AdminDocumentationModalComponent, { fullscreen: true })
        const modalComponent: AdminDocumentationModalComponent = modalRef.componentInstance
        modalComponent.chantierCode = this.chantierDomainService.selectedChantierCode()
        modalComponent.documentsDataSource = {
            getRows: (params: IServerSideGetRowsParams): void => {
                const { sortModel, startRow, filterModel } = params.request
                const pageSize = 5
                const pageNumber = startRow / pageSize
                const selectedChantierCode = this.chantierDomainService.selectedChantierCode()
                this.documentGedHttpService
                    .getDocumentsChantier(selectedChantierCode, pageSize, pageNumber, sortModel, filterModel)
                    .subscribe((documentsPage: PageResponse<DocumentGedDto>) => {
                        params.success({ rowData: documentsPage.content, rowCount: documentsPage.totalElements })
                    })
            },
        }
        modalComponent.documentClicked
            .pipe(
                takeUntilDestroyed(modalComponent.destroyRef),
                switchMap((document) =>
                    this.pieceJointeHttpService
                        .getPhotoById(document.document_id)
                        .pipe(tap(({ lambdaUrl }) => this.document.defaultView.open(lambdaUrl))),
                ),
            )
            .subscribe()
        modalComponent.updateDocumentIsActive
            .pipe(
                takeUntilDestroyed(modalComponent.destroyRef),
                switchMap((updateDocumentIsActiveEvent: UpdateDocumentIsActiveEvent) =>
                    this.documentGedHttpService.updateDocumentIsActive(updateDocumentIsActiveEvent.documentId, updateDocumentIsActiveEvent.active).pipe(
                        tap({
                            next: () => {
                                modalComponent.grid.refreshServerSide()
                            },
                        }),
                    ),
                ),
            )
            .subscribe()
        modalComponent.updateDocumentIsFavori
            .pipe(
                takeUntilDestroyed(modalComponent.destroyRef),
                switchMap((updateDocumentIsFavoriEvent: UpdateDocumentIsFavoriEvent) =>
                    this.documentGedHttpService.updateDocumentIsFavori(updateDocumentIsFavoriEvent.documentId, updateDocumentIsFavoriEvent.favori).pipe(
                        tap({
                            next: () => {
                                modalComponent.grid.refreshServerSide()
                            },
                            error: () => {
                                modalComponent.grid.refreshServerSide()
                            },
                        }),
                    ),
                ),
            )
            .subscribe()
        modalComponent.uploadDocument
            .pipe(
                takeUntilDestroyed(modalComponent.destroyRef),
                switchMap((documentUploadEvent: DocumentUploadEvent) =>
                    this.pieceJointeHttpService.getUploadUrl(documentUploadEvent.form.value.file).pipe(combineLatestWith(of(documentUploadEvent))),
                ),
                switchMap(([pieceJointeUploadDto, documentUploadEvent]) =>
                    this.pieceJointeHttpService
                        .uploadPieceJointeToS3(documentUploadEvent.form.value.file, pieceJointeUploadDto)
                        .pipe(combineLatestWith(of(documentUploadEvent), of(pieceJointeUploadDto))),
                ),
                switchMap(([, documentUploadEvent, pieceJointeUploadDto]) => {
                    const codeChantier = this.chantierDomainService.selectedChantierCode()
                    const { fileName, codeTypeDocumentGed } = documentUploadEvent.form.value
                    return this.documentGedHttpService
                        .saveDocument({
                            fileName,
                            codeTypeDocumentGed,
                            codeChantier,
                            lambdaId: pieceJointeUploadDto.filename,
                        })
                        .pipe(combineLatestWith(of(documentUploadEvent)))
                }),
                catchError(() => {
                    this.globalDomainService.stopSpinner()
                    return EMPTY
                }),
                tap(([documentGedDto, documentUploadEvent]) => {
                    this.globalDomainService.stopSpinner()
                    this.toastr.success(`Le document ${documentGedDto.document_fileName} a bien été ajouté`)
                    documentUploadEvent.ngFormRef.nativeElement.reset()
                    documentUploadEvent.ngForm.resetForm()
                    documentUploadEvent.form.reset({
                        fileName: '',
                        codeTypeDocumentGed: '',
                        file: null,
                    })

                    modalComponent.grid.refreshServerSide()
                }),
            )
            .subscribe()
        modalRef.dismissed.pipe(takeUntilDestroyed(modalComponent.destroyRef)).subscribe(() => {
            this.applicationHttpService
                .fetchData('DOCUMENTATION_CHANTIER', this.chantierDomainService.selectedChantierCode())
                .pipe(
                    tap((appInfo) => {
                        const dtos: DocumentGedDto[] = JSON.parse(appInfo.infos)
                        this.data = dtos.map((dto) => this.mapDocumentDtoToDocument(dto))
                    }),
                    tap(() => this.cdr.markForCheck()),
                )
                .subscribe()
        })
    }

    private mapDocumentDtoToDocument(dto: DocumentGedDto): DocumentGed {
        return {
            certificatRealisation_fileName: dto.certificatRealisation_fileName,
            certificatRealisation_id: dto.certificatRealisation_id,
            chantier_code: dto.chantier_code,
            chantier_nom: dto.chantier_nom,
            certificatRealisation_lambdaId: dto.certificatRealisation_lambdaId,
            createdBy_nom: dto.createdBy_nom,
            createdBy_prenom: dto.createdBy_prenom,
            document_fileName: dto.document_fileName,
            document_id: dto.document_id,
            document_lambdaId: dto.document_lambdaId,
            documentSigne_fileName: dto.documentSigne_fileName,
            documentSigne_id: dto.documentSigne_id,
            documentSigne_lambdaId: dto.documentSigne_lambdaId,
            enveloppeId: dto.enveloppeId,
            typeDocumentGed_nom: dto.typeDocumentGed_nom,
            createdDate: parseCustomDate(dto.createdDate),
            dateSignature: parseCustomDate(dto.dateSignature),
            collaborateur: dto.collaborateur,
        }
    }
}
