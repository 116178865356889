export enum ParamCodeEnum {
    footerContactMail = 'footer_contact_mail',
    footerContactMailIris = 'footer_contact_mail_iris',
    globalMessage = 'global_message',
    globalMessageIris = 'global_message_iris',
    formationMajDate = 'DATE_MAJ_CERTIFICATIONS',
    urlVideoSecurite = 'VIDEO_ACCUEIL_SECURITE',
    urlVideoSecuritePortugaise = 'VIDEO_ACCUEIL_SECURITE_PORTUG',
    dateValiditeActualite = 'DATE_VALIDITE_ACTUALITE',
    dateValiditeActualiteIris = 'DATE_VALIDITE_ACTUALITE_IRIS',
    wifiCourt = 'CODE_WIFI_COURTE_DUREE',
    wifiLong = 'CODE_WIFI_LONGUE_DUREE',
    portletStacLienApplication = 'PORTLET_STAC_LIEN_APPLICATION',
    portletStacLienDescription = 'PORTLET_STAC_LIEN_DESCRIPTION',
    infosAccueilChantier = 'INFOS_ACCUEIL_CHANTIER',
    portletboosterboxtexte = 'PORTLET_BOOSTERBOX_TEXTE',
}
