import { inject, Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { PageResponse } from './dto/page-dto'
import { Interimaire } from '../../models/interimaire'
import { CreateInterimaireDto } from './dto/create-interimaire.dto'
import { RetourCreatePointageCompagnonDto } from './dto/retour-create-pointage-compagnon.dto'
import { CreateInterimairePointageCompagnonDto } from './dto/create-interimaire-pointage-compagnon.dto'

@Injectable({
    providedIn: 'root',
})
export class InterimaireHttpService {
    private static readonly URL = environment.baseUrl + '/interimaires'
    private readonly http = inject(HttpClient)

    findByCodeChantier(
        codeChantier: string,
        pageNum: number = 0,
        pageSize: number = 20,
    ): Observable<PageResponse<Interimaire>> {
        return this.http
            .get<
                PageResponse<Interimaire>
            >(`${InterimaireHttpService.URL}/chantier?codeChantier=${codeChantier}&page=${pageNum}&size=${pageSize}`)
            .pipe(
                map((response) => {
                    const interimairePageResponse: PageResponse<Interimaire> = {
                        ...response,
                        content: response.content.map((int) => new Interimaire(int)),
                    }
                    return interimairePageResponse
                }),
            )
    }

    findInterimaires(keywords: string, pageNum: number = 0): Observable<PageResponse<Interimaire>> {
        return this.http
            .get<
                PageResponse<Interimaire>
            >(`${InterimaireHttpService.URL}/find?keyword=${keywords}&page=${pageNum}&size=20`)
            .pipe(
                map((response) => {
                    const interimairePageResponse: PageResponse<Interimaire> = {
                        ...response,
                        content: response.content.map((int) => new Interimaire(int)),
                    }
                    return interimairePageResponse
                }),
            )
    }

    getInterimaireByCarteBtp(carteBtp: string): Observable<Interimaire> {
        return this.http
            .get<Interimaire>(`${InterimaireHttpService.URL}/carteBtp?carteBtp=${carteBtp}`)
            .pipe(map((int) => new Interimaire(int)))
    }

    getInterimaireLightByCarteBtp(carteBtp: string): Observable<Interimaire> {
        return this.http
            .get<Interimaire>(`${InterimaireHttpService.URL}/carteBtpLight?carteBtp=${carteBtp}`)
            .pipe(map((int) => new Interimaire(int)))
    }

    getInterimaireByIdAndBirthday(interimaireId: number, birthday: string, motif: string): Observable<Interimaire> {
        return this.http
            .get<Interimaire>(
                `${InterimaireHttpService.URL}/idAndBirthday?id=${interimaireId}&birthday=${birthday}&motif=${motif}`,
            )
            .pipe(map((int) => new Interimaire(int)))
    }

    getInterimaireLightByIdAndBirthday(
        interimaireId: number,
        birthday: string,
        motif: string,
    ): Observable<Interimaire> {
        return this.http
            .get<Interimaire>(
                `${InterimaireHttpService.URL}/idAndBirthdayLight?id=${interimaireId}&birthday=${birthday}&motif=${motif}`,
            )
            .pipe(map((int) => new Interimaire(int)))
    }

    buildAccueilSecuritePdf(
        interimaireId: number,
        dateAccueil: string,
        chantierCodeAccueil: string,
    ): Observable<HttpResponse<Blob>> {
        const path = `${InterimaireHttpService.URL}/${interimaireId}/generate-pdf?codeChantier=${chantierCodeAccueil}&dateAccueil=${dateAccueil}`
        return this.http.get(path, { observe: 'response', responseType: 'blob' })
    }

    buildInterimairesChantierCsv(chantierCode: string): Observable<HttpResponse<Blob>> {
        const path = `${InterimaireHttpService.URL}/generate-csv?codeChantier=${chantierCode}`
        return this.http.get(path, { observe: 'response', responseType: 'blob' })
    }

    createInterimaire(createInterimaireDto: CreateInterimaireDto): Observable<Interimaire> {
        return this.http.post<Interimaire>(`${InterimaireHttpService.URL}`, createInterimaireDto)
    }

    updateInterimaire(interimaireId: number, createInterimaireDto: CreateInterimaireDto): Observable<Interimaire> {
        return this.http.put<Interimaire>(`${InterimaireHttpService.URL}/${interimaireId}`, createInterimaireDto)
    }

    createInterimairePointageCompagnon(
        dto: CreateInterimairePointageCompagnonDto,
    ): Observable<RetourCreatePointageCompagnonDto> {
        return this.http.post<RetourCreatePointageCompagnonDto>(
            InterimaireHttpService.URL + '/createInterimairePointageCompagnon',
            dto,
        )
    }

    getAllContratsInterimaire(matricule: string): Observable<PageResponse<Interimaire>> {
        return this.http.get<PageResponse<Interimaire>>(
            InterimaireHttpService.URL + '/matricule?codeMatricule=' + matricule + '&size=30',
        )
    }

    getInterimaireByMatricule(matricule: string): Observable<Interimaire> {
        return this.http
            .get<
                PageResponse<Interimaire>
            >(InterimaireHttpService.URL + '/matricule?codeMatricule=' + matricule + '&size=1')
            .pipe(map((page) => page.content[0]))
    }

    findInterimairesByNomPrenomCarteBtp(searchTerm: string): Observable<PageResponse<Interimaire>> {
        return this.http
            .get<
                PageResponse<Interimaire>
            >(InterimaireHttpService.URL + '/findByNomPrenomCarteBtp?keyword=' + searchTerm + '&page=0&size=30')
            .pipe(
                map((response) => {
                    const interimairePageResponse: PageResponse<Interimaire> = {
                        ...response,
                        content: response.content.map((int) => new Interimaire(int)),
                    }
                    return interimairePageResponse
                }),
            )
    }
}
