import { AbstractControl, ValidatorFn } from '@angular/forms'

export function maxFileSize(maxFileSize: number): ValidatorFn {
    return (control: AbstractControl) => {
        if (!control.value) {
            return null
        }
        if (control.value?.size > maxFileSize) {
            return {
                maxFileSize: {
                    expectedSize: maxFileSize,
                    actualSize: control.value?.size,
                },
            }
        }
        return null
    }
}
